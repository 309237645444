<template>
  <PageWithLayout :isBodyBg="false">
    <BoxView title="Board" :grid="100">
      <BoardComponent
        :boardData="boardData"
        :contextMenu="contextMenu"
        :searchParams.sync="searchParams"
        :dataList="dataList"
        :paginationData="paginationData"
        :checkboxOnlyOne.sync="checkboxOnlyOne"
        :selectedDataList.sync="selectedDataList"
        @onClickRow="rowId => onClickRow(rowId)"
        @onSearch="onSearch()">
      </BoardComponent>
    </BoxView>
    <BoxView title="Board-no data" :grid="100">
      <BoardComponent
        :boardData="boardData"
        :searchParams.sync="searchParamsNoData"
        :dataList="[]"
        :paginationData="paginationNoData">
        <template v-slot:headRight>
          <Button
            btnSize="small"
            btnStyle="fourthly"
            text="선택삭제"/>
          <Button
            btnSize="small"
            btnStyle="primary"
            text="등록하기"/>
        </template>
      </BoardComponent>
    </BoxView>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import BoardComponent from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board'; // Board
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertIdToText, convertIdToColor } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default {
  name: 'Board',
  components:{
    PageWithLayout,
    BoxView,
    BoardComponent,
    Button
  },
  mounted(){
    this.onSearch();
  },
  data(){
    return{
      boardData:{
        title:'게시판',
        subTitle:'필터 / sort',
        drawDataList: [
          {
            title:'',
            width:'42px',
            value:'',
            isCheckbox:true
          },
          {
            title:'No.',
            width:'50px',
            value:'id',
            isSorting: true
          },
          {
            title:'badge',
            width:'69px',
            value:'status',
            filter: { name:'convertIdToText', value:'dummy_status' },
            badge: { badgeColorFilter:{ name:'convertIdToColor', dataListName:'dummy_status' }, badgeFill: false }
          },
          {
            title:'statusSelect',
            width:'180px',
            value:'cs_status',
            statusSelect:{
              dataList:'common_cs_status',
            }
          },
          {
            title:'말줄임',
            width:'',
            value:'title',
            isEllip: true,
            isAlignLeft: true
          },
          {
            title:'말줄임X',
            width:'70px',
            value:'content',
            isAlignLeft: true
          },
          {
            title:'img',
            width:'52px',
            value:'name',
            img:{
              maxWidth:40,
              maxHeight:40,
            },
            valueCustom:[
              { 
                type: 'text',
                value: 'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_'
              },
              {
                type: 'value',
                value:'name',
              },
              { 
                type: 'text',
                value: '.png'
              },
            ],
          },
          {
            title:'mark',
            width:'60px',
            value:'name',
            emptyValueText:'-'
          },
          {
            title:'일시',
            width:'136px',
            value:'date',
            filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
            class: { name:'td_under' }
          },
          {
            title:'color',
            width:'54px',
            value:'status2',
            filter: { name:'convertIdToText', value:'dummy_status2' },
            icon: {
              iconName:'',
              size:10,
              iconColor:'',
              style:{margin:'4px 4px 0 0',opacity:'0.6'},
              iconNameConvert:(value) => convertIdToText(value,'dummy_status2_icon'),
              iconColorFilter:{ name:'convertIdToColor', dataListName:'dummy_status2'} 
            },
            class: { classColorFilter:{ name:'convertIdToColor', dataListName:'dummy_status2', prev:'fw_bold tc' } }
          },
          {
            title:'switch',
            width:'80px',
            value:'switch',
            switch: { text:'Y', onValue: 'Y', offText:'N', offValue:'N' },
          },
          {
            title:'조건별',
            width:'70px',
            value:'status',
            buttonCondition: [
              { condition:'TODO', text:'진행', size:'small', style:'secondary_border' },
              { condition:'DOING', text:'완료', size:'small', style:'primary_border' },
              { condition:'HOLD', text:'진행', size:'small', style:'secondary_border' },
            ],
            emptyValueText:'-'
          },
          {
            title:'버튼',
            width:'60px',
            value:'',
            button: { text:'X', size:'small', style:'fourthly_border' },
          },
        ],
        markData : [
          {
            id: 'name', value: 'kerry',
          },
        ],
        option:{
          isTotal: true,
          isSize: true
        }
      },
      contextMenu:[
        { icon:'♻️', text:'진행중 상태 메뉴', viewIf:{ value:'status', condition:'DOING' }, event:(data) => this.onClickContextMenu(data) },
        { icon:'📝', text:'할일 상태 메뉴', viewIf:{ value:'status', condition:'TODO' }, event:(data) => this.onClickContextMenu(data) },
        { icon:'✅', text:'완료 상태 메뉴', viewIf:{ value:'status', condition:'DONE' }, event:(data) => this.onClickContextMenu(data) },
        { icon:'🚫', text:'보류 상태 메뉴', viewIf:{ value:'status', condition:'HOLD' }, event:(data) => this.onClickContextMenu(data) },

        { icon:'🙌', text:'CS 접수 상태 메뉴', viewIf:{ value:'cs_status', condition:'REGERVVATION' }, event:(data) => this.onClickContextCSMenu(data) },
        { icon:'💁‍♀️', text:'CS 응대중 상태 메뉴', viewIf:{ value:'cs_status', condition:'ING' }, event:(data) => this.onClickContextCSMenu(data) },
        { icon:'🤝', text:'CS 완료 상태 메뉴', viewIf:{ value:'cs_status', condition:'COMPLETE' }, event:(data) => this.onClickContextCSMenu(data) },
      ],
      checkboxOnlyOne: false,
      selectedDataList: [],
      dataList:[],
      // 필터 SORt
      searchParams:{
        page:0,
        pageSize:10,
        direction: 'DESC'
      },
      paginationData:{
        totalCount:14,
        totalPage:2
      },
      // no data
      searchParamsNoData:{
        page:0,
        pageSize:10,
        direction: 'DESC'
      },
      paginationNoData:{
        totalCount:0,
        totalPage:0
      }
    }
  },
  methods:{
    onClickContextMenu(data){
      this.$store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `${convertIdToText(data.status,'dummy_status')} 상태 메뉴를 선택하셨습니다`,
        closeBtnText: '확인',
      });
    },
    onClickContextCSMenu(data){
      this.$store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `CS ${convertIdToText(data.cs_status,'common_cs_status')} 상태 메뉴를 선택하셨습니다`,
        closeBtnText: '확인',
      });
    },
    onClickRow(id){
      this.$store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>click row</strong>id : ${id}`,
        closeBtnText: '확인',
      });
    },
    onSearch(){
      const result = [
        { id:'20', status:'TODO', status2:'None', cs_status:'REGERVVATION', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2022-01-03T12:30:02', switch:'N' },
        { id:'19', status:'TODO', status2:'None', cs_status:'ING', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2022-01-01T20:30:02', switch:'Y' },
        { id:'18', status:'TODO', status2:'Normal', cs_status:'ING', name:'clark', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-30T15:18:02', switch:'N' },
        { id:'17', status:'DOING', status2:'Normal', cs_status:'REGERVVATION', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-30T10:30:02', switch:'N' },
        { id:'16', status:'DOING', status2:'Light', cs_status:'REGERVVATION', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-28T09:58:02', switch:'Y' },
        { id:'15', status:'HOLD', status2:'Light', cs_status:'REGERVVATION', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'14', status:'DOING', status2:'Normal', cs_status:'COMPLETE', name:'', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'13', status:'HOLD', status2:'None', cs_status:'COMPLETE', name:'clark', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'12', status:'DONE', status2:'High', cs_status:'ING', name:'clark', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'11', status:'DONE', status2:'High', cs_status:'COMPLETE', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'10', status:'HOLD', status2:'Normal', cs_status:'REGERVVATION', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2022-01-03T12:30:02', switch:'Y' },
        { id:'9', status:'HOLD', status2:'Normal', cs_status:'ING', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2022-01-01T20:30:02', switch:'N' },
        { id:'8', status:'DONE', status2:'Normal', cs_status:'COMPLETE', name:'', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-30T15:18:02', switch:'N' },
        { id:'7', status:'DONE', status2:'None', cs_status:'ING', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-30T10:30:02', switch:'N' },
        { id:'6', status:'DONE', status2:'Light', cs_status:'COMPLETE', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-28T09:58:02', switch:'' },
        { id:'5', status:'HOLD', status2:'None', cs_status:'COMPLETE', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'4', status:'DONE', status2:'Normal', cs_status:'COMPLETE', name:'clark', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'N' },
        { id:'3', status:'DONE', status2:'Light', cs_status:'COMPLETE', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'Y' },
        { id:'2', status:'DONE', status2:'Normal', cs_status:'COMPLETE', name:'kerry', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'Y' },
        { id:'1', status:'DONE', status2:'Normal', cs_status:'COMPLETE', name:'hari', title:'제목이 들어갑니다', content:'말줄임이 없을 경우', date:'2021-12-27T11:11:02', switch:'Y' }
      ];
      const { page, pageSize } = this.searchParams;
      const startIdx = page * pageSize;
      const endIdx = (page + 1) * pageSize;
      this.dataList = result.slice(startIdx,endIdx);
      this.paginationData.totalCount = result.length;
      this.paginationData.totalPage = Math.floor(result.length / pageSize) || ( result.length > 0 ? 1 : 0 );
      // console.log(this.searchParams)
      // console.log(this.paginationData)
    }
  }
}
</script>

<style scoped>
.box_view::v-deep .body_view{padding:20px 30px 40px}
</style>